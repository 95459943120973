import React, { useState, useEffect, useContext, createContext } from "react";

const IFContext = createContext();

const IFContextProvider = ({ condition, children, fallback }) => {
  const [fullfilled, setFullfilled] = useState(false);
  const [rejected, setRejected] = useState(false);

  useEffect(() => {
    setRejected(false);
    setFullfilled(false);

    if (condition instanceof Promise) {
      condition.then(result => (result ? setFullfilled(true) : setRejected(true))).catch(console.error);
    } else {
      condition ? setFullfilled(true) : setRejected(true);
    }
  }, [condition]);

  if (fallback && !fullfilled && !rejected) {
    return fallback;
  }

  return (
    <IFContext.Provider
      value={{
        fullfilled,
        rejected,
      }}
    >
      {children}
    </IFContext.Provider>
  );
};

export function IF({ condition, children, fallback }) {
  return (
    <IFContextProvider condition={condition} fallback={fallback}>
      {children}
    </IFContextProvider>
  );
}

export function THEN({ children }) {
  const { fullfilled } = useContext(IFContext);
  return fullfilled ? children : null;
}

export function ELSE({ children }) {
  const { rejected } = useContext(IFContext);
  return rejected ? children : null;
}
